import React, { useState } from "react";

import { useSelector } from "react-redux";
import "./index.css"; // Import the CSS file
import { useCreateIngredientWithNameMutation } from "../../../auth/services/ingredientService"; // Adjust the path if needed
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the toast styles

const SpiceLevels = ({ spiceLevels, setSpiceLevels }) => {
  /* // Initialize state with the default list
  const [spiceLevels, setSpiceLevels] = useState([
    { name: "None", checked: false },
    { name: "Mild", checked: false },
    { name: "Medium", checked: false },
    { name: "Spicy", checked: false },
    { name: "Spicy AF", checked: false },
  ]); */
  const [newSpiceLevel, setNewSpiceLevel] = useState("");
  const [createIngredientWithName] = useCreateIngredientWithNameMutation();

  // Retrieve userId and estoreId from Redux store
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // Submit ingredient to server
  const submitIngredientData = async (name, category) => {
    const data = {
      name,
      description: "",
      category,
      price: {
        basePrice: 0,
        discount: 1.1,
        currency: "USD",
      },
      estoreId,
    };

    try {
      const result = await createIngredientWithName({ data }).unwrap();
      toast.success("Spice Item submitted successfully:", result);
    } catch (error) {
      toast.error("Error submitting data:", error);
    }
  };
  // Handle adding a new spice level
  const addSpiceLevel = async () => {
    if (newSpiceLevel.trim()) {
      await submitIngredientData(newSpiceLevel.trim(), "spice");
      setSpiceLevels([
        ...spiceLevels,
        { name: newSpiceLevel.trim(), checked: false },
      ]);
      setNewSpiceLevel(""); // Clear the input field
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedSpiceLevels = spiceLevels.map((level, i) =>
      i === index ? { ...level, checked: !level.checked } : level
    );
    setSpiceLevels(updatedSpiceLevels);
  };

  return (
    <div className="spice-levels-container">
      <h3 className="spice-levels-title">Spice Levels</h3>
      <ul className="spice-levels-list">
        {spiceLevels.map((level, index) => (
          <li className="spice-levels-list-item" key={index}>
            <input
              id={`spice-checkbox-${index}`} // Unique id for each checkbox
              name={`spice-checkbox-${index}`}
              type="checkbox"
              checked={level.checked}
              onChange={() => handleCheckboxChange(index)}
            />
            <label htmlFor={`spice-checkbox-${index}`}>{level.name}</label>
          </li>
        ))}
      </ul>
      <div>
        <input
          id={`spice-checkbox`}
          name={`spice-checkbox`}
          type="text"
          className="spice-levels-input"
          value={newSpiceLevel}
          onChange={(e) => setNewSpiceLevel(e.target.value)}
          placeholder="Add new spice level"
        />
        <button className="spice-levels-button" onClick={addSpiceLevel}>
          Add
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SpiceLevels;
