import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages";
import Login from "./pages/Login";
import SignUp from "./pages/Signup";
import Settings from "./pages/Settings";
import Footer from "./components/Footer";
import UserProfile from "./pages/user/UserProfile";
import ProfileScreen from "./pages/user/ProfileScreen";
import ProfileEditScreen from "./pages/user/ProfileEditScreen";
import Profile from "./pages/Profile";
import Dashboard from "./components/Dashboard";
import AddProduct from "./components/Dashboard/AddProduct";
import EditProduct from "./components/Dashboard/EditProduct";
import Product from "./components/Dashboard/Product";
import AddFoodItem from "./components/Dashboard/AddFoodItem";
import EditFoodItem from "./components/Dashboard/EditFoodItem";
import NavBar from "./components/NavBar/index";
import FoodItemDetails from "./components/Dashboard/FoodItemDetails";
import IngredientDetails from "./components/Dashboard/IngredientDetails";
import AddIngredient from "./components/Dashboard/AddIngredient";
import EditIngredient from "./components/Dashboard/EditIngredient";
import IngredientList from "./components/Dashboard/IngredientList";
import CartPage from "./components/Dashboard/CartPage";
import CartItemDetails from "./components/Dashboard/CartItemDetails";
import CartItemEdit from "./components/Dashboard/CartItemEdit";
import EstoreUpdate from "./components/Dashboard/EstoreUpdate";
import AllergyAdd from "./pages/allergy/AllergyAdd";
import AllergyDetails from "./pages/allergy/AllergyDetails";
import AllergyList from "./pages/allergy/AllergyList";
import EditAllergy from "./pages/allergy/EditAllergy";
function App() {
  return (
    <Router>
      <>
        <NavBar />
        <div className="flex justify-center items-center">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/settings/:id" element={<Settings />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/profiles" element={<Profile />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/profile-edit" element={<ProfileEditScreen />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/cartitem/:id" element={<CartItemDetails />} />
            <Route path="/cart-item-edit/:id" element={<CartItemEdit />} />
            <Route
              path="/dashboard/estore-edit/:id"
              element={<EstoreUpdate />}
            />

            <Route
              path="/dashboard/product/ingredientlist/:id"
              element={<IngredientDetails />}
            />
            <Route
              path="/dashboard/product/ingredientlist/edit/:id"
              element={<EditIngredient />}
            />
            <Route
              path="/dashboard/product/addingredient"
              element={<AddIngredient />}
            />
            <Route
              path="/dashboard/product/ingredientlist"
              element={<IngredientList />}
            />
            <Route path="/dashboard/product" element={<Product />} />
            <Route
              path="/dashboard/product/addproduct"
              element={<AddProduct />}
            />
            <Route
              path="/dashboard/product/editproduct/:id"
              element={<EditProduct />}
            />
            <Route path="/dashboard/product" element={<Product />} />
            <Route
              path="/dashboard/product/addfoodItem"
              element={<AddFoodItem />}
            />
            <Route
              path="/dashboard/product/editfooditem/:id"
              element={<EditFoodItem />}
            />
            <Route
              path="/dashboard/product/fooditem/:id"
              element={<FoodItemDetails />}
            />
            <Route path="/allergy" element={<AllergyList />} />
            <Route path="/allergy/add" element={<AllergyAdd />} />
            <Route path="/allergy/:id" element={<AllergyDetails />} />
            <Route path="/allergy/edit/:id" element={<EditAllergy />} />
          </Routes>
        </div>
      </>

      <Footer />
    </Router>
  );
}

export default App;
