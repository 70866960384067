import React from "react";
import PropTypes from "prop-types";

const Tabs = ({ children, defaultActiveTab = 0 }) => {
  const [activeTab, setActiveTab] = React.useState(defaultActiveTab);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="flex flex-col">
      <ul className="flex flex-row border-b border-gray-200">
        {React.Children.map(children, (tab, index) => (
          <li
            key={index}
            className={`flex-1 p-4 cursor-pointer ${
              activeTab === index ? "border-b-2 border-blue-500" : ""
            }`}
            onClick={() => handleTabClick(index)}
          >
            {tab.props.title}
          </li>
        ))}
      </ul>
      <div className="p-4">{children[activeTab]}</div>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  defaultActiveTab: PropTypes.number,
};

export default Tabs;
