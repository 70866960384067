import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEstoreList } from "../../redux/auth/estoreSlice";
import { setDefaultEstore } from "../../redux/auth/userSlice"; // Assume you have an action for this
import Spinner from "../../components/Spinner";
import Company from "./Company";
import { useNavigate } from "react-router-dom";

const CompanyList = ({ refreshEstoreList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { estoreList, isLoading, error } = useSelector((state) => state.estore);

  useEffect(() => {
    if (estoreList.length === 0 && !isLoading && !error) {
      dispatch(fetchEstoreList());
    }
  }, [dispatch, estoreList.length, isLoading, error]);

  /*   // Function to refresh the list on button click
  const handleRefresh = () => {
    dispatch(fetchEstoreList());
    refreshEstoreList(); // Call the refresh function passed as prop
  }; */
  // Handle the selection of a company
  const handleView = (estoreId) => {
    navigate("/dashboard");
  };
  // Handle the selection of a company
  const handleActivate = async (estoreId) => {
    dispatch(setDefaultEstore(estoreId)); // Dispatch action to set default estore
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col items-center">
      {/* <button
        onClick={handleRefresh}
        className="mb-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        disabled={isLoading}
      >
        {isLoading ? "Loading..." : "Refresh List"}
      </button> */}
      <div className="flex flex-wrap justify-center gap-4">
        {estoreList?.map((company) => (
          <Company
            key={company.estoreId}
            company={company}
            //onSelect={handleSelect}
            onActivate={handleActivate}
            onView={handleView}
          />
        ))}
      </div>
    </div>
  );
};

export default CompanyList;
