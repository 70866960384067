import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import authReducer from "./auth/authSlice";
import cartReducer from "./auth/cartSlice";
import foodItemCartReducer from "./auth/foodItemCartSlice";
import foodItemReducer from "./auth/foodItemSlice";
import productCartReducer from "./auth/productCartSlice";
import productReducer from "./auth/productSlice";
import userReducer from "./auth/jwtSlice";
import { authApi } from "../auth/services/authService";
import counterReducer from "./features/counterSlice";
import estoreReducer from "./auth/estoreSlice";
import { foodItemApi } from "../auth/services/foodItemService";
import { allergyApi } from "../auth/services/allergyService";
import { productApi } from "../auth/services/productService";
import { ingredientApi } from "../auth/services/ingredientService";
import { estoreApi } from "../auth/services/estoreService";

// Define persist config for the auth slice
const persistConfig = {
  key: "root",
  storage,
};

// Create a persisted reducer for auth
const persistedAuthReducer = persistReducer(persistConfig, authReducer);

const rootReducer = combineReducers({
  // Add other reducer slice
  estore: estoreReducer, // Adding the slice reducer
  user: userReducer,
  cart: cartReducer,
  productCart: productCartReducer,
  product: productReducer,
  foodItem: foodItemReducer,
  foodItemCart: foodItemCartReducer,
  counter: counterReducer,
  auth: persistedAuthReducer,
  // Add API reducers RTK
  [estoreApi.reducerPath]: estoreApi.reducer, // Adding the API reducer
  [foodItemApi.reducerPath]: foodItemApi.reducer,
  [allergyApi.reducerPath]: allergyApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [ingredientApi.reducerPath]: ingredientApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(authApi.middleware)
      .concat(allergyApi.middleware)
      .concat(foodItemApi.middleware)
      .concat(productApi.middleware)
      .concat(ingredientApi.middleware)
      .concat(estoreApi.middleware),
});

// Create the persistor
const persistor = persistStore(store);

export { store, persistor };
