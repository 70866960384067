// estoreSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const backendURL = "http://localhost:8080/citylibrary/api/v1/public";
export const fetchEstoreList = createAsyncThunk(
  "auth/estoreProfileList",
  async (_, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      const { data } = await axios.get(`${backendURL}/auth/estore`, config);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateEstore = createAsyncThunk(
  "auth/estoreUpdate",
  async (estoreRequest, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      const { data } = await axios.put(
        `${backendURL}/auth/estore`,
        estoreRequest,
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        alert(error.response.data.message + " from estoreSlice.js");
        return rejectWithValue(error.response.data.message);
      } else {
        alert(error.message + " from estoreSlice.js");
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateEstoreLogo = createAsyncThunk(
  "auth/estoreUpdateLogo",
  async ({ id, file }, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;
    // Check if the file is present
    if (!file) {
      const errorMessage = "No file provided for uploading.";
      alert(errorMessage); // Optionally alert the user
      return rejectWithValue(errorMessage); // Return an error
    }
    try {
      // Create a FormData object to hold the image file
      const formData = new FormData();
      formData.append("file", file); // The "file" key should match the server-side expectation
      // Create the config with headers
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      // "Content-Type": "multipart/form-data",
      // Make the PATCH request to update the logo
      const { data } = await axios.patch(
        `${backendURL}/auth/estore/${id}/logo`,
        formData,
        config
      );
      return data;
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      alert(JSON.stringify(errorMessage) + " from estoreSlice.js");
      return rejectWithValue(errorMessage);
    }
  }
);

export const createEstore = createAsyncThunk(
  "auth/estoreCreate",
  async (estoreRequest, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      const { data } = await axios.post(
        `${backendURL}/auth/estore`,
        estoreRequest,
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        alert(error.response.data.message + " from estoreSlice.js");
        return rejectWithValue(error.response.data.message);
      } else {
        alert(error.message + " from estoreSlice.js");
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  isLoading: false,
  isSuccess: false,
  estore: {},
  estoreList: [],
  error: null,
};

const estoreSlice = createSlice({
  name: "estore",
  initialState,
  reducers: {
    resetEstore: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.estore = {};
      state.estoreList = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEstoreList.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(fetchEstoreList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.estoreList = action.payload;
      })
      .addCase(fetchEstoreList.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })
      .addCase(updateEstore.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(updateEstore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.estoreList.findIndex(
          (estore) => estore.estoreId === action.payload.estoreId
        );
        if (index !== -1) {
          state.estoreList[index] = action.payload; // Update the existing estore in the list
        }
      })

      .addCase(updateEstore.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })
      .addCase(createEstore.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(createEstore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.estore = action.payload;
        state.estoreList.push(action.payload); // Add new estore to the list
      })

      .addCase(createEstore.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { resetEstore } = estoreSlice.actions;
export default estoreSlice.reducer;
