import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "./index.css";
import OutOfStockCheckbox from "../OutOfStock";
import Availability from "../Availability";
import AvailabilityDays from "../AvailabilityDays";
import SpiceLevels from "../SpiceLevel";
import GarnishToppings from "../GarnishToppings";
import LiquidAddons from "../LiquidAddOns";
import SolidAddons from "../SolidAddons";
import Ingredients from "../Ingredients";
import {
  useUpdateFoodItemDetailsMutation,
  useGetFoodItemInfoQuery,
} from "../../../auth/services/foodItemService";
import FoodCourse from "../FoodCourse";
import FoodItemFileUpload from "../../utils/FoodItemUpload";

const EditFoodItem = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the food item ID from the URL

  const [updateFoodItemDetails] = useUpdateFoodItemDetailsMutation();

  // Fetch existing food item details to populate the form
  const { data: foodItem, isLoading, error } = useGetFoodItemInfoQuery(id);

  // Retrieve userId and estoreId from Redux store
  const userId = useSelector((state) => state.auth.userInfo.id);
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // State for form inputs
  const [name, setName] = useState("");
  const [cuisineType, setCuisineType] = useState("");
  const [daysAvailable, setDaysAvailable] = useState([]);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [isPublic, setIsPublic] = useState(true);

  const [garnishes, setGarnishes] = useState([]);
  const [foodCourses, setFoodCourses] = useState([]);
  const [liquidAddons, setLiquidAddons] = useState([]);
  const [solidAddons, setSolidAddons] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [spiceLevels, setSpiceLevels] = useState([]);

  useEffect(() => {
    if (foodItem) {
      setName(foodItem.name);
      setCuisineType(foodItem.cuisine_type);
      setDaysAvailable(foodItem.days_available.split(", "));
      setDescription(foodItem.description);
      setPrice(foodItem.price.basePrice);
      setIsPublic(foodItem.visibility === "PUBLIC");

      // Initialize combined ingredients
      setGarnishes(
        foodItem.combinedIngredients.garnish.map((g) => ({
          name: g,
          checked: true,
        }))
      );
      setSpiceLevels(
        foodItem.combinedIngredients.spiceLevels.map((s) => ({
          name: s,
          checked: true,
        }))
      );
      setSolidAddons(foodItem.combinedIngredients.solidAddons || []);
      setLiquidAddons(foodItem.combinedIngredients.liquidAddons || []);
      setIngredients(foodItem.combinedIngredients.otherIngredients || []);
      setFoodCourses(
        foodItem.combinedIngredients.foodCourses.map((c) => ({
          name: c,
          checked: true,
        }))
      );
    }
  }, [foodItem]);

  // Submit data to server
  const submitData = async () => {
    const data = {
      name,
      image: foodItem.image,
      spice_level: spiceLevels
        .filter((spice) => spice.checked)
        .map((spice) => spice.name)
        .join(", "), // Format spice levels as a comma-separated string
      cuisine_type: cuisineType,
      price: {
        basePrice: price,
        discount: 1.1,
        currency: "USD",
      },
      description,
      ingredients: ingredients.join(", "), // Comma-separated ingredients
      days_available: daysAvailable.join(", "), // Comma-separated days
      visibility: isPublic ? "PUBLIC" : "PRIVATE",
      estoreId,
      userId,
      combinedIngredients: {
        garnish: garnishes
          .filter((garnish) => garnish.checked)
          .map((garnish) => garnish.name),
        spiceLevels: spiceLevels
          .filter((spice) => spice.checked)
          .map((spice) => spice.name),
        solidAddons: solidAddons,
        liquidAddons: liquidAddons,
        otherIngredients: ingredients,
        foodCourses: foodCourses
          .filter((course) => course.checked)
          .map((course) => course.name),
      },
    };

    try {
      const result = await updateFoodItemDetails({ id, data }).unwrap();
      console.log("Data updated successfully:", result);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitData();
  };

  const handleBackClick = () => {
    navigate("/dashboard");
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching food item details</div>;
  }

  return (
    <div className="edit-food-item-container">
      <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
        Back to Dashboard
      </button>
      <h3>Edit Food Item</h3>
      <FoodItemFileUpload />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="product-name">Product Name</label>
          <input
            id="product-name"
            name="product-name"
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="cuisine-type">Cuisine Type</label>
          <input
            id="cuisine-type"
            name="cuisine-type"
            type="text"
            className="form-control"
            value={cuisineType}
            onChange={(e) => setCuisineType(e.target.value)}
            required
          />
        </div>
        <FoodCourse foodCourses={foodCourses} setFoodCourses={setFoodCourses} />
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            className="form-control"
            rows="3"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="price">Price</label>
          <input
            id="price"
            name="price"
            type="number"
            className="form-control"
            value={price}
            onChange={(e) => setPrice(parseFloat(e.target.value))}
            required
          />
        </div>

        <GarnishToppings garnishes={garnishes} setGarnishes={setGarnishes} />
        <LiquidAddons
          liquidAddons={liquidAddons}
          setLiquidAddons={setLiquidAddons}
        />
        <SolidAddons
          solidAddons={solidAddons}
          setSolidAddons={setSolidAddons}
        />
        <Ingredients
          ingredients={ingredients}
          setIngredients={setIngredients}
        />
        <SpiceLevels
          spiceLevels={spiceLevels}
          setSpiceLevels={setSpiceLevels}
        />

        <div className="d-flex">
          <div className="form-group me-2">
            <Availability isPublic={isPublic} setIsPublic={setIsPublic} />
          </div>
          <div className="form-group">
            <OutOfStockCheckbox
              isOutOfStock={isOutOfStock}
              setIsOutOfStock={setIsOutOfStock}
            />
          </div>
        </div>

        <AvailabilityDays setDaysAvailable={setDaysAvailable} />

        <button type="submit" className="btn btn-primary">
          Update
        </button>
      </form>
    </div>
  );
};

export default EditFoodItem;
