import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../../redux/auth/userSlice"; // Assume these actions are defined in userSlice
import { useGetUserDetailsQuery } from "../../auth/services/authService";
import { setCredentials } from "../../redux/auth/authSlice";

const imageUrl = "https://horeloimgs.s3.amazonaws.com/user/";

const ProfileScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo, userToken } = useSelector((state) => state.auth);
  const userId = useSelector((state) => state.auth.userInfo?.userId);

  const { data, isFetching } = useGetUserDetailsQuery(undefined, {
    skip: !userToken,
    // perform a refetch every 15mins
    pollingInterval: 900000,
  });

  // Load user info on component mount
  useEffect(() => {
    if (userId) {
      dispatch(getUserInfo(userId)); // Fetch user info by userId
      //console.log(userToken);
      //alert(JSON.stringify(userInfo));
    }
  }, [dispatch, userId, userInfo, userToken]);
  useEffect(() => {
    if (data) dispatch(setCredentials(data));
  }, [data, dispatch]);
  if (isFetching) return <div>Loading...</div>;
  return (
    <div className="flex flex-col justify-evenly items-center">
      <h1 className="font-semibold text-sm lg:text-lg">
        {userInfo?.preferredName}
      </h1>
      <p className="photo-wrapper p-2">
        <img
          src={imageUrl + userInfo.userProfile?.avatar}
          alt="Avatar"
          className="w-32 h-32 rounded-full mx-auto"
        />
      </p>
      <h2 className="m-1 font-bold text-xl lg:text-2xl">
        <strong>{userInfo?.preferredName}</strong>
      </h2>
      {userInfo ? (
        <div>
          <p>Email: {userInfo.email}</p>
          <p>First Name: {userInfo.userProfile?.firstName}</p>
          <p>Last Name: {userInfo.userProfile?.lastName}</p>
          <p>Phone Number: {userInfo.userProfile?.phoneNumber}</p>
          <p>Title: {userInfo.userProfile?.title}</p>
          <p>Short msg: {userInfo.userProfile?.description}</p>
          <p>Profile: {userInfo.userProfile?.avatar}</p>
        </div>
      ) : (
        <p>No user details found.</p>
      )}

      <div className="inline-flex items-center rounded-md shadow-sm my-8">
        <button
          className="text-slate-800 hover:text-blue-600 text-sm bg-white hover:bg-slate-100 border border-slate-200 rounded-l-lg font-medium px-4 py-2 inline-flex space-x-1 items-center"
          onClick={() => navigate("/profile-edit")}
        >
          <span>Edit</span>
        </button>
      </div>
    </div>
  );
};

export default ProfileScreen;
