import { BsCart3 } from "react-icons/bs";
import { useSelector } from "react-redux";

const CartUtil = () => {
  const { productCart } = useSelector((state) => state.productCart);
  const { foodItemCart } = useSelector((state) => state.foodItemCart);

  return (
    <div style={styles.container}>
      <BsCart3 style={styles.icon} />
      <div style={styles.label}>
        <label>{productCart.length + foodItemCart.length}</label>
      </div>
    </div>
  );
};

export default CartUtil;

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative", // Ensure that child elements can be positioned relative to this container
  },
  icon: {
    fontSize: "25px", // Size of the shopping cart icon
    color: "gray", // Color of the icon
  },
  label: {
    position: "absolute", // Overlay the label on top of the icon
    top: "-16px", // Adjust to control vertical position
    left: "10px", // Adjust to control horizontal position
    fontSize: "16px", // Font size for the cart count
    color: "gray", // Text color
    fontWeight: "bold", // Bold text
  },
};
