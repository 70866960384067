import React, { useEffect, useState } from "react";
import AddressUpdate from "../../utils/AddressUpdate";
import OpenDaysAndHoursEditor from "../../utils/OpenDaysAndHoursEditor";
import {
  useGetEstoreInfoQuery,
  useUpdateEstoreDetailsMutation,
  estoreApi,
} from "../../../auth/services/estoreService";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "./index.css";
import EstoreFileUpload from "../../utils/EstoreFileUpload";
const imageUrl = "https://horeloimgs.s3.amazonaws.com/estore/";
const EstoreUpdate = () => {
  const { id } = useParams();
  const [updateEstoreDetails] = useUpdateEstoreDetailsMutation();

  // Access cached estore data from the store using estoreApi's reducerPath
  const estoreDataFromCache = useSelector((state) =>
    estoreApi.endpoints.getEstoreInfo.select(id)(state)
  );

  // Fetch and cache data using RTK Query
  const { data: fetchedEstore } = useGetEstoreInfoQuery(id);

  const [estoredata, setEstoreData] = useState({
    phoneNumber: "",
    name: "",
    email: "",
    logo: "",
    timeZone: "",
    orderPolicy: {
      defaultWaitingTime: 0,
      processingTime: 0,
      confirmedTime: 0,
    },
    primary_address: {
      addressId: 0,
      street: "",
      city: "",
      state: "",
      zipCode: "",
    },
    openDaysAndHours: {
      openPeriodsByDay: [],
    },
  });

  // Sync form state with fetched estore data or cached estore data
  useEffect(() => {
    const dataToSet = estoreDataFromCache?.data || fetchedEstore;
    if (dataToSet && dataToSet !== estoredata) {
      setEstoreData(dataToSet);
    }
  }, [estoreDataFromCache, fetchedEstore, estoredata]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEstoreData((prevEstoreData) => ({
      ...prevEstoreData,
      [name]: value,
    }));
  };

  const handlePolicyChange = (e) => {
    const { name, value } = e.target;
    setEstoreData((prevEstoreData) => ({
      ...prevEstoreData,
      orderPolicy: {
        ...prevEstoreData.orderPolicy,
        [name]: Number(value),
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await updateEstoreDetails({
        id,
        data: estoredata,
      }).unwrap();
      console.log("Data updated successfully:", result);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  if (!estoreDataFromCache && !fetchedEstore) {
    return <div>Loading...</div>;
  }

  return (
    <div className="estore-update-container">
      <h2>Update EstoreData</h2>
      <EstoreFileUpload />
      <img
        src={
          estoredata.logo.includes("https://")
            ? estoredata.logo
            : `${imageUrl}${estoredata.logo}`
        }
        alt="logo"
      />

      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={estoredata.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Phone Number:</label>
          <input
            type="text"
            name="phoneNumber"
            value={estoredata.phoneNumber}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={estoredata.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Time Zone:</label>
          <input
            type="text"
            name="timeZone"
            value={estoredata.timeZone}
            onChange={handleChange}
            required
          />
        </div>
        <h3>Order Policy</h3>
        <div>
          <label>Default Waiting Time (minutes):</label>
          <input
            type="number"
            name="defaultWaitingTime"
            value={estoredata?.orderPolicy?.defaultWaitingTime}
            onChange={handlePolicyChange}
            required
          />
        </div>
        <div>
          <label>Processing Time (minutes):</label>
          <input
            type="number"
            name="processingTime"
            value={estoredata?.orderPolicy?.processingTime}
            onChange={handlePolicyChange}
            required
          />
        </div>
        <div>
          <label>Confirmed Time (minutes):</label>
          <input
            type="number"
            name="confirmedTime"
            value={estoredata?.orderPolicy?.confirmedTime}
            onChange={handlePolicyChange}
            required
          />
        </div>
        <button type="submit">Update Estore</button>
      </form>
      <OpenDaysAndHoursEditor
        openDaysAndHours={estoredata?.openDaysAndHours}
        onChange={(newOpenDaysAndHours) =>
          setEstoreData((prevEstoreData) => ({
            ...prevEstoreData,
            openDaysAndHours: newOpenDaysAndHours,
          }))
        }
      />
      <AddressUpdate
        estoreAddress={estoredata.primary_address}
        onChangeonChange={(newAddress) =>
          setEstoreData((prevEstoreData) => ({
            ...prevEstoreData,
            primary_address: newAddress,
          }))
        }
      />
      {/* <pre>{JSON.stringify(estoredata, null, 2)}</pre> */}
    </div>
  );
};

export default EstoreUpdate;
