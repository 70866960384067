/* eslint-disable no-unused-vars */
import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
// eslint-disable-next-line
import { useGetAllIngredientsQuery } from "../../../auth/services/ingredientService";

const IngredientList = () => {
  // Use the query hook to fetch data from the API
  //const { data: ingredients, error, isLoading } = useGetAllIngredientsQuery();
  const {
    data: ingredients,
    error,
    isLoading,
  } = useGetAllIngredientsQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  // Check for loading state
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Check for error state
  if (error) {
    return <div>Error loading food items.</div>;
  }

  // Dynamic text color logic based on status
  const textColor = (status) => {
    if (status.toLowerCase() === "public") {
      return "green";
    } else if (status === "private") {
      return "orange";
    } else {
      return "blue";
    }
  };

  return (
    <div className="shadow mx-1 px-3 bg-light rounded-3 ">
      <div className="d-flex align-items-center justify-content-between p-3 mt-3">
        <h4 className="mb-0">Ingredients List</h4>
        <div className="ml-auto">
          <button className="btn mr-2 btn-primary">See All</button>
          <Link className="btn btn-primary">Add</Link>
        </div>
      </div>
      <div className="">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Category</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {ingredients.map((ingredient, index) => (
              <tr key={index}>
                <td>{ingredient.name}</td>
                <td>{ingredient.category}</td>
                <td>{ingredient.price.basePrice}</td>
                <td className="flex gap-2">
                  <Link
                    to={`${ingredient.ingredientId}`}
                    className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded"
                  >
                    View
                  </Link>
                  <Link
                    to={`edit/${ingredient.ingredientId}`}
                    className="bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded"
                  >
                    Edit
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IngredientList;
