import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./index.css";
import OutOfStockCheckbox from "../OutOfStock";
import Availability from "../Availability";
import AvailabilityDays from "../AvailabilityDays";
import SpiceLevels from "../SpiceLevel";
import GarnishToppings from "../GarnishToppings";
import LiquidAddons from "../LiquidAddOns";
import SolidAddons from "../SolidAddons";
import Ingredients from "../Ingredients";
import { useCreateFoodItemDetailsMutation } from "../../../auth/services/foodItemService";
import FoodCourse from "../FoodCourse";

const AddFoodItem = () => {
  const navigate = useNavigate();
  const [createFoodItemDetails] = useCreateFoodItemDetailsMutation();

  // Retrieve userId and estoreId from Redux store
  const userId = useSelector((state) => state.auth.userInfo.id);
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // State for form inputs
  const [name, setName] = useState("");
  const [cuisineType, setCuisineType] = useState("");
  const [days_available, setDays_available] = useState([]);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [isPublic, setIsPublic] = useState(true);

  const [garnishes, setGarnishes] = useState([
    { name: "Onions", checked: false },
    { name: "Tomatoes", checked: false },
  ]);
  const [foodCourses, setFoodCourses] = useState([
    { name: "Starters", checked: false },
    { name: "Main dish", checked: false },
    { name: "Final course", checked: false },
  ]);
  const [liquidAddons, setLiquidAddons] = useState([]);
  const [solidAddons, setSolidAddons] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [spiceLevels, setSpiceLevels] = useState([
    { name: "None", checked: false },
    { name: "Mild", checked: false },
    { name: "Medium", checked: false },
    { name: "Spicy", checked: false },
    { name: "Spicy AF", checked: false },
  ]);

  const combinedIngredients = {
    garnish: garnishes
      .filter((garnish) => garnish.checked)
      .map((garnish) => garnish.name),
    spiceLevels: spiceLevels
      .filter((spice) => spice.checked)
      .map((spice) => spice.name),
    solidAddons: solidAddons,
    liquidAddons: liquidAddons,
    otherIngredients: ingredients,
    foodCourses: foodCourses
      .filter((course) => course.checked)
      .map((course) => course.name),
  };

  // Submit data to server
  const submitData = async () => {
    const data = {
      name,
      image: "https://horeloimgs.s3.amazonaws.com/tacos.jpg", // Example static image
      spice_level: spiceLevels
        .filter((spice) => spice.checked)
        .map((spice) => spice.name)
        .join(", "), // Format spice levels as a comma-separated string
      cuisine_type: cuisineType,
      price: {
        basePrice: price,
        discount: 1.1,
        currency: "USD",
      },
      description,
      ingredients: ingredients.join(", "), // Comma-separated ingredients
      days_available: days_available.join(", "), // Comma-separated days
      visibility: isPublic ? "PUBLIC" : "PRIVATE",
      estoreId,
      userId,
      combinedIngredients,
    };

    try {
      const result = await createFoodItemDetails(data).unwrap();
      console.log("Data submitted successfully:", result);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitData();
  };

  const handleBackClick = () => {
    navigate("/dashboard");
  };

  return (
    <div className="add-food-item-container">
      <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
        Back to Dashboard
      </button>
      <h3>Add New Food Item</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="product-name">Product Name</label>
          <input
            id="product-name"
            name="product-name"
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="cuisine-type">Cuisine Type</label>
          <input
            id="cuisine-type"
            name="cuisine-type"
            type="text"
            className="form-control"
            value={cuisineType}
            onChange={(e) => setCuisineType(e.target.value)}
            required
          />
        </div>
        <FoodCourse foodCourses={foodCourses} setFoodCourses={setFoodCourses} />
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            className="form-control"
            rows="3"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="price">Price</label>
          <input
            id="price"
            name="price"
            type="number"
            className="form-control"
            value={price}
            onChange={(e) => setPrice(parseFloat(e.target.value))}
            required
          />
        </div>

        <GarnishToppings garnishes={garnishes} setGarnishes={setGarnishes} />
        <LiquidAddons
          liquidAddons={liquidAddons}
          setLiquidAddons={setLiquidAddons}
        />
        <SolidAddons
          solidAddons={solidAddons}
          setSolidAddons={setSolidAddons}
        />
        <Ingredients
          ingredients={ingredients}
          setIngredients={setIngredients}
        />
        <SpiceLevels
          spiceLevels={spiceLevels}
          setSpiceLevels={setSpiceLevels}
        />

        <div className="d-flex">
          <div className="form-group me-2">
            <Availability isPublic={isPublic} setIsPublic={setIsPublic} />
          </div>
          <div className="form-group">
            <OutOfStockCheckbox
              isOutOfStock={isOutOfStock}
              setIsOutOfStock={setIsOutOfStock}
            />
          </div>
        </div>

        <AvailabilityDays setDaysAvailable={setDays_available} />

        <button type="submit" className="btn btn-primary">
          Create
        </button>
      </form>
    </div>
  );
};

export default AddFoodItem;
