import React, { useState } from "react";
import "./index.css"; // Import the CSS file

const FoodCourse = ({ foodCourses, setFoodCourses }) => {
  const [newCourse, setNewCourse] = useState("");

  // Handle adding a new course
  const addCourse = () => {
    if (newCourse.trim()) {
      setFoodCourses([
        ...foodCourses,
        { name: newCourse.trim(), checked: true },
      ]);
      setNewCourse(""); // Clear the input field
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedCourses = foodCourses.map((course, i) =>
      i === index ? { ...course, checked: !course.checked } : course
    );
    setFoodCourses(updatedCourses);
  };

  return (
    <div className="course-food-container">
      <h3 className="course-food-title">Course Type</h3>
      <ul className="course-food-list">
        {foodCourses.map((course, index) => (
          <li className="course-food-list-item" key={index}>
            <input
              id={`course-checkbox-${index}`} // Unique id for each checkbox
              name={`course-checkbox-${index}`}
              type="checkbox"
              checked={course.checked}
              onChange={() => handleCheckboxChange(index)}
            />
            <label htmlFor={`course-checkbox-${index}`}>{course.name}</label>
          </li>
        ))}
      </ul>
      <div>
        <input
          id={`course-checkbox}`} // Unique id for each checkbox
          name={`course-checkbox`}
          type="text"
          className="course-food-input"
          value={newCourse}
          onChange={(e) => setNewCourse(e.target.value)}
          placeholder="Add new course"
        />
        <button className="course-food-button" onClick={addCourse}>
          Add
        </button>
      </div>
    </div>
  );
};

export default FoodCourse;
