import React from "react";
import { useGetAllergyInfoQuery } from "../../auth/services/allergyService";
import { useParams, useNavigate } from "react-router-dom";

const AllergyDetails = () => {
  const { id } = useParams();
  const { data: allergy, error, isLoading } = useGetAllergyInfoQuery({ id });
  const navigate = useNavigate();
  const handleAllergyClick = (allergyId) => {
    // Navigate to the allergy details page with the selected allergy ID
    navigate(`/allergy/edit/${id}`);
  };
  if (isLoading) return <p>Loading allergy details...</p>;
  if (error)
    return (
      <>
        <h2>Allergy Details</h2>
        <p>Error loading allergy: {error.message}</p>
      </>
    );

  return (
    <div>
      <h2>Allergy Details</h2>
      <p>Name: {allergy.name}</p>
      <p>Description: {allergy.description}</p>

      <button onClick={() => handleAllergyClick(allergy.allergyId)}>
        Edit
      </button>
    </div>
  );
};

export default AllergyDetails;
