import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetFoodItemInfoQuery } from "../../../auth/services/foodItemService";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; // Import slick styles
import "slick-carousel/slick/slick-theme.css"; // Import slick theme styles

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}

const CartItemEdit = () => {
  const { id } = useParams(); // Get fooditem ID from the URL

  // Fetch fooditem details using the ID
  const { data: fooditem, isLoading, error } = useGetFoodItemInfoQuery(id);

  const categories = [
    { label: "Garnish", field: "garnish" },
    { label: "Spice Levels", field: "spiceLevels" },
    { label: "Solid Addons", field: "solidAddons" },
    { label: "Liquid Addons", field: "liquidAddons" },
    { label: "Other Ingredients", field: "otherIngredients" },
    { label: "Food Courses", field: "foodCourses" },
  ];

  const [selectedItems, setSelectedItems] = useState({
    garnish: "",
    spiceLevels: "",
    solidAddons: "",
    liquidAddons: "",
    otherIngredients: "",
    foodCourses: "",
  });

  useEffect(() => {
    if (fooditem) {
      setSelectedItems({
        garnish: fooditem.combinedIngredients.garnish[0] || "",
        spiceLevels: fooditem.combinedIngredients.spiceLevels[0] || "",
        solidAddons: fooditem.combinedIngredients.solidAddons[0] || "",
        liquidAddons: fooditem.combinedIngredients.liquidAddons[0] || "",
        otherIngredients:
          fooditem.combinedIngredients.otherIngredients[0] || "",
        foodCourses: fooditem.combinedIngredients.foodCourses[0] || "",
      });
    }
  }, [fooditem]);

  const handleSelectionChange = (category, value) => {
    setSelectedItems((prevSelected) => ({
      ...prevSelected,
      [category]: value,
    }));
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  // Display loading or error messages if necessary
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading fooditem details!</div>;
  }

  return (
    <div style={styles.container}>
      <h4>{fooditem.name}</h4>
      <h5>Customize:</h5>

      {/* Slick Carousel for Customization Categories */}
      <Slider {...settings}>
        {categories.map((category, index) => {
          const items = fooditem?.combinedIngredients[category.field] || [];
          if (items.length === 0) return null; // Skip empty categories
          return (
            <div key={index} style={styles.carouselSlide}>
              <h3>{category.label}</h3>
              {items.map((item, itemIndex) => (
                <div key={itemIndex} style={styles.radioLabelContainer}>
                  <label key={itemIndex} style={styles.radioLabel}>
                    <input
                      type="radio"
                      name={category.field}
                      value={item}
                      checked={selectedItems[category.field] === item}
                      onChange={() =>
                        handleSelectionChange(category.field, item)
                      }
                    />
                    {item}
                  </label>
                </div>
              ))}
            </div>
          );
        })}
      </Slider>

      {/* Selected Items Summary */}
      <div style={styles.selectionSummary}>
        <h3>Selected Customizations</h3>
        <ul>
          {Object.entries(selectedItems).map(([category, item]) => (
            <li key={category}>
              <strong>{category}:</strong> {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

// Basic Styles
const styles = {
  container: {
    flexDirection: "column",
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "20px",
    maxWidth: "600px",
    //margin: "20px auto",
    alignItems: "center",
  },
  categorySection: {
    marginBottom: "20px",
  },

  radioLabelContainer: {
    // Row layout for radio buttons
    flexDirection: "row",
    justifyContent: "flex-start", // Align radio buttons to the left
    gap: "10px", // Space between radio buttons
    width: "100%", // Ensure it takes the full width
  },
  radioLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },
  selectionSummary: {
    marginTop: "20px",
    paddingTop: "10px",
    borderTop: "1px solid #ddd",
  },
  carouselSlide: {
    padding: "10px",
  },
};

export default CartItemEdit;
