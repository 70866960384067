import { createSlice } from "@reduxjs/toolkit";
import { registerUser, userLogin, updateUser } from "./authActions";

// initialize userToken from local storage
const userToken = localStorage.getItem("userTokens")
  ? localStorage.getItem("userTokens")
  : null;
const initialState = {
  loading: false,
  userInfo: null,
  userToken: userToken,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
    },
    setUserToken: (state, action) => {
      state.userToken = action.payload.jwtToken;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setCredentials: (state, { payload }) => {
      state.userInfo = payload;
      //state.userToken = payload.jwtToken;
      //localStorage.setItem("userToken", payload.jwtToken); // Store the token in local storage
    },
  },
  extraReducers: (builder) => {
    builder
      // Login user
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload;
        state.userToken = payload.jwtToken;
        localStorage.setItem("userToken", payload.jwtToken); // Store the token in local storage
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // Register user
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // Registration successful
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // update user
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // update successful
      })
      .addCase(updateUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { logout, setCredentials } = authSlice.actions;
export default authSlice.reducer;
