import React from "react";
import { useGetAllAllergiesQuery } from "../../auth/services/allergyService";
import { useNavigate } from "react-router-dom";
const AllergyList = () => {
  const { data: allergies, error, isLoading } = useGetAllAllergiesQuery();

  const navigate = useNavigate();
  const handleAllergyClick = (allergyId) => {
    // Navigate to the allergy details page with the selected allergy ID
    navigate(`/allergy/edit/${allergyId}`);
  };
  if (isLoading) return <p>Loading allergies...</p>;
  if (error) return <p>Error loading allergies: {error.message}</p>;

  return (
    <div>
      <h2>Allergies List</h2>
      <ul>
        {allergies?.map((allergy) => (
          <li
            key={allergy.allergyId}
            onClick={() => handleAllergyClick(allergy.allergyId)}
            style={{ cursor: "pointer", color: "blue" }} // Optional: Styling to indicate clickable item
          >
            {allergy.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AllergyList;
