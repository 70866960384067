import React from "react";

const Availability = ({ isPublic, setIsPublic }) => {
  const handleCheckboxChange = (event) => {
    setIsPublic(event.target.checked); // Update the state in the parent component
  };

  return (
    <div>
      <label htmlFor="is-public" className="d-flex align-items-center">
        <input
          type="checkbox"
          id="is-public"
          name="is-public"
          checked={isPublic}
          onChange={handleCheckboxChange}
          className="me-2"
        />
        Public
      </label>

      {/* Display the public visibility status 
      {isPublic && (
        <p className="mt-2 text-danger">This item is currently public.</p>
      )}
        */}
    </div>
  );
};

export default Availability;
