import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createEstore } from "../../redux/auth/estoreSlice"; // Adjust the path as needed

const CompanyAdd = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const { isLoading } = useSelector((state) => state.estore);

  // Trigger fetching the store list once a new store is successfully created
  /*  useEffect(() => {
    if (isSuccess) {
      dispatch(fetchEstoreList());
      dispatch(resetEstore()); // Reset success state to avoid continuous loop
      reset();
    }
  }, [isSuccess, dispatch, reset]);
 */
  // Form submission handler
  const onSubmit = (data) => {
    if (!isLoading) {
      dispatch(createEstore(data));
    }
  };

  return (
    <>
      <div className="flex flex-col justify-evenly items-center">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col justify-evenly items-center"
        >
          <label>Name:</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            {...register("name", { required: true })}
          />
          <label>Email:</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            {...register("email", { required: true })}
          />

          <label>Phone Number:</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            {...register("phoneNumber", { required: true })}
          />
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            disabled={isLoading} // Disable button while the form is being submitted
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default CompanyAdd;
