import React from "react";

const OutOfStockCheckbox = ({ isOutOfStock, setIsOutOfStock }) => {
  const handleCheckboxChange = (event) => {
    setIsOutOfStock(event.target.checked); // Update the state in the parent component
  };

  return (
    <div>
      <label htmlFor="out-of-stock" className="d-flex align-items-center">
        <input
          type="checkbox"
          id="out-of-stock"
          name="out-of-stock"
          checked={isOutOfStock}
          onChange={handleCheckboxChange}
          className="me-2"
        />
        Out of Stock
      </label>

      {/* Display the out of stock status */}
      {/* {isOutOfStock && (
        <p className="mt-2 text-danger">This item is currently out of stock.</p>
      )} */}
    </div>
  );
};

export default OutOfStockCheckbox;
