import React from "react";
import { useSelector } from "react-redux";

const Company = ({ company, onView, onActivate }) => {
  const { estoreId, name, email } = company;
  const { defaultEstore } = useSelector((state) => state.auth.userInfo);
  //alert("company" + JSON.stringify(company));
  return (
    <div className="flex flex-row justify-evenly items-center gap-2 odd:bg-white even:bg-gray-100 rounded">
      <div className="flex-1 gap-2">{name}</div>
      <div className="flex-1 gap-2">{email}</div>
      <div className="flex flex-row gap-2">
        <button
          onClick={(e) => {
            e.stopPropagation(); // Prevents triggering parent div click
            onView(estoreId);
          }}
          className="text-sm py-1 px-2 bg-blue-500 rounded text-white hover:bg-blue-600"
        >
          View
        </button>
        {estoreId === defaultEstore &&
        (defaultEstore !== 0) & (defaultEstore !== null) ? (
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevents triggering parent div click
              onActivate(0);
            }}
            className="text-sm py-1 px-2 bg-green-500 rounded text-white hover:bg-orange-600"
          >
            DeActivate
          </button>
        ) : (
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevents triggering parent div click
              onActivate(estoreId);
            }}
            className="text-sm py-1 px-2 bg-orange-500 rounded text-white hover:bg-orange-600"
          >
            Activate
          </button>
        )}
      </div>
    </div>
  );
};

export default Company;
