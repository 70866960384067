import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
const Product = () => {
  return (
    <div className="container">
      <Link to="addproduct" className="btn btn-primary">
        Add Product
      </Link>
      <Link to="addfooditem" className="btn btn-primary">
        Add Menu Item
      </Link>
      <Link to="addingredient" className="btn btn-primary">
        Add Ingredient
      </Link>
      <Link to="ingredientlist" className="btn btn-primary">
        View Ingredients
      </Link>
    </div>
  );
};

export default Product;
