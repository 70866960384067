import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCreateIngredientDetailsMutation } from "../../../auth/services/ingredientService"; // Adjust the path if needed
import "./index.css";

const AddIngredient = () => {
  const navigate = useNavigate();
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // use the mutation hook for creating ingredient
  const [createIngredientDetails] = useCreateIngredientDetailsMutation();

  // State to capture form data
  const [ingredientData, setIngredientData] = useState({
    name: "",
    description: "",
    category: "",
    price: {
      basePrice: 0,
      discount: 1.1,
      currency: "USD",
    },
    estoreId, // assuming estoreId and userId are known or from props/store
    //userId: 101,
  });

  // handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "basePrice") {
      setIngredientData((prevData) => ({
        ...prevData,
        price: { ...prevData.price, basePrice: parseFloat(value) }, // Ensure it's a number
      }));
    } else {
      setIngredientData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleBackClick = () => {
    navigate("/dashboard/product");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // make API call to create ingredient
      alert(JSON.stringify(ingredientData));
      await createIngredientDetails(ingredientData).unwrap();
      console.log("Ingredient added successfully!");
      navigate("/dashboard/product/ingredientlist"); // Redirect after adding
    } catch (error) {
      console.error("Error adding ingredient:", error);
    }
  };

  return (
    <div className="add-ingredient-container">
      <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
        Back to Dashboard
      </button>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Ingredient Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={ingredientData.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Ingredient Category</label>
          <select
            className="form-control"
            name="category"
            value={ingredientData.category}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Category</option> {/* Placeholder option */}
            <option value="spices">Spices</option>
            <option value="vegetables">Vegetables</option>
            <option value="proteins">Proteins</option>
            <option value="liquids">Soup/Broth</option>
            <option value="grains">Grains</option>
            <option value="dairy">Dairy</option>
            <option value="condiments">Condiments</option>
            <option value="others">other</option>
          </select>
        </div>
        <div className="form-group">
          <label>Ingredient Description</label>
          <textarea
            className="form-control"
            rows="3"
            name="description"
            value={ingredientData.description}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label>Ingredient Price</label>
          <input
            type="number"
            className="form-control"
            name="basePrice"
            value={ingredientData.price.basePrice}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Add Ingredient
        </button>
      </form>
    </div>
  );
};

export default AddIngredient;
