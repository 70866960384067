import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./index.css"; // Import the CSS file
import { useCreateIngredientDetailsMutation } from "../../../auth/services/ingredientService"; // Adjust the path if needed
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the toast styles

const SolidAddons = ({ solidAddons, setSolidAddons }) => {
  const solidAddonsArray = ["Tofu", "fish", "chicken", "Meat ball", "Egg"];

  const [searchInput, setSearchInput] = useState("");
  const [filteredSolidAddons, setFilteredSolidAddons] = useState([]);
  const [createIngredientDetails] = useCreateIngredientDetailsMutation();

  // Retrieve userId and estoreId from Redux store
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // Submit ingredient to server
  const submitIngredientData = async (name, category) => {
    const data = {
      name,
      description: "",
      category,
      price: {
        basePrice: 0,
        discount: 1.1,
        currency: "USD",
      },
      estoreId,
    };

    try {
      const result = await createIngredientDetails(data).unwrap();
      toast.success("Protein Item submitted successfully:", result);
    } catch (error) {
      toast.error("Error submitting data:", error);
    }
  };
  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);

    if (value.length > 0) {
      const filtered = solidAddonsArray.filter((addon) =>
        addon.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSolidAddons(filtered);
    } else {
      setFilteredSolidAddons([]);
    }
  };

  // Add new solid addon from search or filtered list
  const handleAddSolidAddon = async (addon) => {
    if (!solidAddons.includes(addon)) {
      await submitIngredientData(addon.trim(), "proteins");
      setSolidAddons([...solidAddons, addon]);
    }
    setSearchInput("");
    setFilteredSolidAddons([]);
  };

  // Remove selected solid addon
  const handleRemoveSolidAddon = (addon) => {
    setSolidAddons(solidAddons.filter((item) => item !== addon));
  };

  return (
    <div className="form-group">
      <label htmlFor="input-solid">Solid AddOn(s)</label>
      <div className="d-flex">
        <input
          id="input-solid"
          name="input-solid"
          type="text"
          className="form-control me-2"
          value={searchInput}
          onChange={handleSearchChange}
          placeholder="Search for Solid Add-ons"
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleAddSolidAddon(searchInput)}
        >
          Add
        </button>
      </div>
      <ul className="list-group">
        {filteredSolidAddons.map((addon, index) => (
          <li
            key={index}
            className="list-group-item"
            onClick={() => handleAddSolidAddon(addon)}
          >
            {addon}
          </li>
        ))}
      </ul>
      <div className="selected-ingredients mt-2">
        {solidAddons.map((addon, index) => (
          <label
            key={index}
            className="badge badge-primary mr-2"
            onClick={() => handleRemoveSolidAddon(addon)}
          >
            {addon} &times;
          </label>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};
export default SolidAddons;
