import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useGetFoodItemInfoQuery } from "../../../auth/services/foodItemService";
const CartItemDetails = ({ onAddToCart }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  // Fetch food item data using the provided ID
  const { data: foodItem, isLoading, error } = useGetFoodItemInfoQuery(id);

  // Show loading message while data is being fetched
  if (isLoading) {
    return <p>Loading...</p>;
  }

  // Handle errors
  if (error) {
    return <p>Error loading food item details.</p>;
  }
  return (
    <div style={styles.container}>
      <h2>{foodItem.name}</h2>
      <img src={foodItem.image} alt={foodItem.name} style={styles.image} />

      <div style={styles.infoSection}>
        <p>
          <strong>Spice Level:</strong> {foodItem.spice_level}
        </p>
        <p>
          <strong>Cuisine Type:</strong> {foodItem.cuisine_type}
        </p>
        <p>
          <strong>Price:</strong> ${foodItem.price.basePrice} (Discount: $
          {foodItem.price.discount}) {foodItem.price.currency}
        </p>
        <p>
          <strong>Description:</strong> {foodItem.description}
        </p>
        <p>
          <strong>Days Available:</strong> {foodItem.days_available}
        </p>
        <p>
          <strong>Visibility:</strong> {foodItem.visibility}
        </p>
        <p>
          <strong>Ingredients:</strong> {foodItem.ingredients}
        </p>

        {/* Combined Ingredients */}
        {/* 
        <div style={styles.combinedIngredientsSection}>
          <h3>Combined Ingredients</h3>
          <p>
            <strong>Garnish:</strong>{" "}
            {foodItem.combinedIngredients.garnish.join(", ")}
          </p>
          <p>
            <strong>Spice Levels:</strong>{" "}
            {foodItem.combinedIngredients.spiceLevels.join(", ")}
          </p>
          <p>
            <strong>Solid Addons:</strong>{" "}
            {foodItem.combinedIngredients.solidAddons.join(", ")}
          </p>
          <p>
            <strong>Liquid Addons:</strong>{" "}
            {foodItem.combinedIngredients.liquidAddons.join(", ")}
          </p>
          <p>
            <strong>Other Ingredients:</strong>{" "}
            {foodItem.combinedIngredients.otherIngredients.join(", ")}
          </p>
          <p>
            <strong>Food Courses:</strong>{" "}
            {foodItem.combinedIngredients.foodCourses.join(", ")}
          </p>
        </div>
 */}
        {/* Estore and User Info */}
        <div style={styles.additionalInfo}>
          <p>
            <strong>Estore:</strong> {foodItem.estore.name}
          </p>
        </div>

        {/* Add to Cart Button */}
        <button
          style={styles.addToCartButton}
          onClick={() => onAddToCart(foodItem.foodItemId)}
        >
          Add to Cart
        </button>
        <button
          style={styles.addToCartButton}
          onClick={() => navigate(`/cart-item-edit/${foodItem.foodItemId}`)}
        >
          Customize
        </button>
      </div>
    </div>
  );
};

// Basic Styles
const styles = {
  container: {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "20px",
    maxWidth: "600px",
    margin: "20px auto",
  },
  image: {
    width: "100%",
    maxHeight: "300px",
    objectFit: "cover",
    borderRadius: "8px",
    marginBottom: "15px",
  },
  infoSection: {
    fontSize: "16px",
    lineHeight: "1.6",
  },
  combinedIngredientsSection: {
    marginTop: "20px",
    paddingTop: "10px",
    borderTop: "1px solid #ddd",
  },
  additionalInfo: {
    marginTop: "20px",
  },
  addToCartButton: {
    marginTop: "20px",
    padding: "10px 15px",
    backgroundColor: "#28a745",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  },
};

export default CartItemDetails;
