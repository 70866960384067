import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  useUpdateProductDetailsMutation,
  useGetProductInfoQuery,
} from "../../../auth/services/productService"; // Adjust the path if needed

import "./index.css";
import ProductFileUpload from "../../utils/ProductFileUpload";

const imageUrl = "https://horeloimgs.s3.amazonaws.com/product/";

const EditProduct = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the product ID from the URL
  const userId = useSelector((state) => state.auth.userInfo.id);
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // use the mutation hook for updating product
  const [updateProductDetails] = useUpdateProductDetailsMutation();

  // Fetch existing product details to populate the form
  const { data: product, isLoading, error } = useGetProductInfoQuery(id);

  // State to capture form data
  const [productData, setProductData] = useState({
    name: "",
    description: "",
    category: "",
    price: {
      basePrice: 0,
      discount: 1.1,
      currency: "USD",
    },
    estoreId,
    userId, // Use userId from store
  });

  // Populate the form with fetched product data
  useEffect(() => {
    if (product) {
      setProductData({
        name: product.name,
        description: product.description,
        category: product.category,
        price: {
          basePrice: product.price.basePrice,
          discount: product.price.discount,
          currency: product.price.currency || "USD",
        },
        estoreId,
        userId,
      });
    }
  }, [product, estoreId, userId]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "basePrice") {
      setProductData((prevData) => ({
        ...prevData,
        price: { ...prevData.price, basePrice: parseFloat(value) }, // Ensure it's a number
      }));
    } else {
      setProductData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleBackClick = () => {
    navigate("/dashboard");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Add the product ID to the data being submitted
      const data = { ...productData };

      // Make API call to update the product
      await updateProductDetails(id, data).unwrap();
      console.log("Product updated successfully!");
      navigate("/dashboard"); // Redirect after updating
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading product data</div>;

  return (
    <div className="edit-product-container">
      <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
        Back to Dashboard
      </button>
      <ProductFileUpload />
      <img
        src={
          product.image.includes("https://")
            ? product.image
            : `${imageUrl}${product.image}`
        }
        alt="logo"
      />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Product Name{id}</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={productData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Product Description</label>
          <textarea
            className="form-control"
            rows="3"
            name="description"
            value={productData.description}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label>Product Category</label>
          <input
            type="text"
            className="form-control"
            name="category"
            value={productData.category}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Product Price</label>
          <input
            type="number"
            className="form-control"
            name="basePrice"
            value={productData.price.basePrice}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Update Product
        </button>
      </form>
    </div>
  );
};

export default EditProduct;
