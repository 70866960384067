// src/components/FileUpload.js
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateFoodItemLogo } from "../../redux/auth/foodItemSlice";
import { useParams } from "react-router-dom";

const FoodItemFileUpload = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [imgUrl, setImgUrl] = useState();
  function handleFileChange(e) {
    console.log(e.target.files);
    setFile(e.target.files[0]);
    setImgUrl(URL.createObjectURL(e.target.files[0]));
  }
  const submitData = async () => {
    if (file) dispatch(updateFoodItemLogo({ id, file }));
  };

  return (
    <div className="file-upload-container">
      <label>Logo</label>
      <h2>Add Image:</h2>
      <input type="file" onChange={(e) => handleFileChange(e)} />
      <img src={imgUrl} alt="logo" />

      <button onClick={submitData}>Update Logo</button>
    </div>
  );
};

export default FoodItemFileUpload;
