import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./index.css"; // Import the CSS file
import { useCreateIngredientWithNameMutation } from "../../../auth/services/ingredientService"; // Adjust the path if needed
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the toast styles

const LiquidAddons = ({ liquidAddons, setLiquidAddons }) => {
  const liquidAddonsArray = [
    "Olive Oil",
    "Vinegar",
    "Soy Sauce",
    "Hot Sauce",
    "Honey",
    "Mustard",
    "Lemon Juice",
    "Balsamic Vinegar",
    "Worcestershire Sauce",
    "Sriracha",
  ];

  const [searchInput, setSearchInput] = useState("");
  const [filteredLiquidAddons, setFilteredLiquidAddons] = useState([]);
  const [createIngredientWithName] = useCreateIngredientWithNameMutation();

  // Retrieve userId and estoreId from Redux store
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // Submit ingredient to server
  const submitIngredientData = async (name, category) => {
    const data = {
      name,
      description: "",
      category,
      price: {
        basePrice: 0,
        discount: 1.1,
        currency: "USD",
      },
      estoreId,
    };

    try {
      const result = await createIngredientWithName({ data }).unwrap();
      toast.success("Garnish Item submitted successfully:", result);
    } catch (error) {
      toast.error("Error submitting data:", error);
    }
  };
  // Handle adding a new garnish or topping
  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);

    if (value.length > 0) {
      const filtered = liquidAddonsArray.filter((addon) =>
        addon.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredLiquidAddons(filtered);
    } else {
      setFilteredLiquidAddons([]);
    }
  };

  // Add new liquid addon from search or filtered list
  const handleAddLiquidAddon = async (addon) => {
    if (!liquidAddons.includes(addon)) {
      await submitIngredientData(addon.trim(), "liquids");
      setLiquidAddons([...liquidAddons, addon]);
    }
    setSearchInput("");
    setFilteredLiquidAddons([]);
  };

  // Remove selected liquid addon
  const handleRemoveLiquidAddon = (addon) => {
    setLiquidAddons(liquidAddons.filter((item) => item !== addon));
  };

  return (
    <div className="form-group">
      <label htmlFor="input-liquid">Liquid AddOn(s)</label>
      <div className="d-flex">
        <input
          id="input-liquid"
          name="input-liquid"
          type="text"
          className="form-control me-2"
          value={searchInput}
          onChange={handleSearchChange}
          placeholder="Search for Liquid Add-ons"
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleAddLiquidAddon(searchInput)}
        >
          Add
        </button>
      </div>
      <ul className="list-group">
        {filteredLiquidAddons.map((addon, index) => (
          <li
            key={index}
            className="list-group-item"
            onClick={() => handleAddLiquidAddon(addon)}
          >
            {addon}
          </li>
        ))}
      </ul>
      <div className="selected-ingredients mt-2">
        {liquidAddons.map((addon, index) => (
          <label
            key={index}
            className="badge badge-primary mr-2"
            onClick={() => handleRemoveLiquidAddon(addon)}
          >
            {addon} &times;
          </label>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};
export default LiquidAddons;
