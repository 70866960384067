import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: [], // Cart items will be stored as objects with id, name, price, quantity, etc.
};

const cartSlice = createSlice({
  name: "cart",
  initialState,

  reducers: {
    addToCart: (state, action) => {
      const item = action.payload; // The item passed in the payload
      const existingItem = state.cart.find(
        (cartItem) => cartItem.foodItemId === item.foodItemId
      );

      if (existingItem) {
        // If the item already exists in the cart, increase its quantity
        existingItem.quantity += 1;
      } else {
        // If it's a new item, add it to the cart with an initial quantity of 1
        state.cart.push({ ...item, quantity: 1 });
      }
    },

    removeFromCart: (state, action) => {
      const itemId = action.payload; // The id of the item to be removed
      state.cart = state.cart.filter(
        (cartItem) => cartItem.foodItemId !== itemId
      );
    },

    updateCartItemQuantity: (state, action) => {
      const { foodItemId, quantity } = action.payload; // payload contains item id and new quantity
      const item = state.cart.find(
        (cartItem) => cartItem.foodItemId === foodItemId
      );

      if (item) {
        // Update the quantity, but if the quantity is less than 1, remove the item from the cart
        if (quantity > 0) {
          item.quantity = quantity;
        } else {
          state.cart = state.cart.filter(
            (cartItem) => cartItem.foodItemId !== foodItemId
          );
        }
      }
    },
  },
});

export const { addToCart, updateCartItemQuantity, removeFromCart } =
  cartSlice.actions;
export default cartSlice.reducer;
