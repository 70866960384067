import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./index.css"; // Import the CSS file
import { useCreateIngredientDetailsMutation } from "../../../auth/services/ingredientService"; // Adjust the path if needed
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the toast styles

const Ingredients = ({ ingredients, setIngredients }) => {
  const ingredientsArray = [
    "Tomato",
    "Lettuce",
    "Cheese",
    "Onion",
    "Garlic",
    "Pepper",
    "Salt",
    "Chicken",
    "Beef",
    "Pork",
  ];

  const [searchInput, setSearchInput] = useState("");
  const [filteredIngredients, setFilteredIngredients] = useState([]);
  const [createIngredientDetails] = useCreateIngredientDetailsMutation();

  // Retrieve userId and estoreId from Redux store
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // Submit ingredient to server
  const submitIngredientData = async (name, category) => {
    const data = {
      name,
      description: "",
      category,
      price: {
        basePrice: 0,
        discount: 1.1,
        currency: "USD",
      },
      estoreId,
    };

    try {
      const result = await createIngredientDetails(data).unwrap();
      toast.success("Ingredient Data submitted successfully:", result);
    } catch (error) {
      toast.error("Error submitting data:", error);
    }
  };
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);

    if (value.length > 0) {
      const filtered = ingredientsArray.filter((ingredient) =>
        ingredient.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredIngredients(filtered);
    } else {
      setFilteredIngredients([]);
    }
  };

  const handleAddIngredient = async (ingredient) => {
    if (!ingredients.includes(ingredient)) {
      await submitIngredientData(ingredient.trim(), "ingredients");
      setIngredients([...ingredients, ingredient]);
    }
    setSearchInput("");
    setFilteredIngredients([]);
  };

  const handleRemoveIngredient = (ingredient) => {
    setIngredients(ingredients.filter((item) => item !== ingredient));
  };

  return (
    <div className="form-group">
      <label htmlFor="input-ingredients">Ingredients</label>
      <div className="d-flex">
        <input
          id="input-ingredients"
          name="input-ingredients"
          type="text"
          className="form-control me-2"
          value={searchInput}
          onChange={handleSearchChange}
          placeholder="Search for ingredients"
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleAddIngredient(searchInput)}
        >
          Add
        </button>
      </div>
      <ul className="list-group">
        {filteredIngredients.map((ingredient, index) => (
          <li
            key={index}
            className="list-group-item"
            onClick={() => handleAddIngredient(ingredient)}
          >
            {ingredient}
          </li>
        ))}
      </ul>
      <div className="selected-ingredients mt-2">
        {ingredients.map((ingredient, index) => (
          <label
            key={index}
            className="badge badge-primary mr-2"
            onClick={() => handleRemoveIngredient(ingredient)}
          >
            {ingredient} &times;
          </label>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Ingredients;
