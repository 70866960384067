import React, { useState } from "react";
import "./index.css"; // Import the CSS file

const AvailabilityDays = ({ setDaysAvailable }) => {
  // Initialize state with the default list of days
  const [availabilityDays, setAvailabilityDays] = useState([
    { name: "all", checked: false },
    { name: "Mon", checked: false },
    { name: "Tue", checked: false },
    { name: "Wed", checked: false },
    { name: "Thu", checked: false },
    { name: "Fri", checked: false },
    { name: "Sat", checked: false },
    { name: "Sun", checked: false },
  ]);

  const handleCheckboxChange = (index) => {
    const updatedDays = availabilityDays.map((day, i) => {
      if (index === 0) {
        return { ...day, checked: !availabilityDays[0].checked };
      }
      return i === index ? { ...day, checked: !day.checked } : day;
    });

    // Update availabilityDays state
    setAvailabilityDays(updatedDays);

    // Send selected days to the parent
    const selectedDays = updatedDays
      .filter((day) => day.checked && day.name !== "all")
      .map((day) => day.name);
    //.join(", ");
    setDaysAvailable(selectedDays);
  };
  return (
    <div className="availability-days-container">
      <h3 className="availability-days-title">Availability Days</h3>
      <ul className="availability-days-list">
        {availabilityDays.map((day, index) => (
          <li className="availability-days-list-item" key={index}>
            <input
              id={`availability-days-${index}`}
              name={`availability-days-${index}`}
              type="checkbox"
              checked={day.checked}
              onChange={() => handleCheckboxChange(index)}
            />
            {day.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AvailabilityDays;
