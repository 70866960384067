import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetEstoreInfoQuery,
  useUpdateEstoreAddressMutation,
} from "../../auth/services/estoreService";

const AddressUpdate = ({ estoreAddress }) => {
  const { id } = useParams();
  const [address, setAddress] = useState({
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });

  const [updateEstoreAddress] = useUpdateEstoreAddressMutation();
  const { data: estore, isLoading, error } = useGetEstoreInfoQuery(id);

  useEffect(() => {
    if (estore && estore.primary_address) {
      setAddress(estore.primary_address);
    }
  }, [estore]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({ ...prevAddress, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await updateEstoreAddress({
        id,
        data: address,
      }).unwrap();
      console.log("Data updated successfully:", result);
      alert(JSON.stringify(result));
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  if (isLoading) {
    return <div>Loading... Address</div>;
  }

  if (error) {
    return (
      <div>
        <p style={{ color: "red" }}>{JSON.stringify(error)}</p>
        Error fetching Address details
      </div>
    );
  }

  return (
    <div>
      <h2>Update Address</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Street: </label>
          <input
            type="text"
            name="street"
            value={address.street}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>City:</label>
          <input
            type="text"
            name="city"
            value={address.city}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>State:</label>
          <input
            type="text"
            name="state"
            value={address.state}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Zip Code:</label>
          <input
            type="text"
            name="zipCode"
            value={address.zipCode}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Country:</label>
          <input
            type="text"
            name="country"
            value={address.country}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Update Address</button>
      </form>
    </div>
  );
};

export default AddressUpdate;
