import React from "react";
import { useGetAllFoodItemsAndProductsQuery } from "../auth/services/foodItemService"; // Assuming you have a product API service
import { useDispatch, useSelector } from "react-redux";
import { addToProductCart } from "../redux/auth/productCartSlice"; // Import cart actions
import { addToFoodItemCart } from "../redux/auth/foodItemCartSlice"; // Import cart actions
import { FaPlusCircle } from "react-icons/fa";

import "./index.css";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { foodItemCart } = useSelector((state) => state.foodItemCart);
  const { productCart } = useSelector((state) => state.productCart);
  const {
    data: combinedData,
    isLoading,
    error,
  } = useGetAllFoodItemsAndProductsQuery(); // Assuming a hook to fetch products

  if (isLoading) {
    return <div>Loading products...</div>;
  }

  if (error) {
    return <div>Error fetching products: {error.message}</div>;
  }

  if (!combinedData || combinedData.length === 0) {
    return <div>No products available.</div>;
  }
  // Helper function to check if the product is already in the cart
  const isItemInFoodItemCart = (productId) => {
    return foodItemCart.some((cartItem) => cartItem.foodItemId === productId);
  };
  // Helper function to check if the product is already in the cart
  const isItemInProductCart = (productId) => {
    return productCart.some((cartItem) => cartItem.productId === productId);
  };
  // Add product foodItem to cart handler
  const handleAddToFoodItemCart = (product) => {
    dispatch(addToFoodItemCart(product)); // Dispatch the action to add product to cart
  };
  // Add product to cart handler
  const handleAddToProductCart = (product) => {
    dispatch(addToProductCart(product)); // Dispatch the action to add product to cart
  };

  return (
    <div className="product-container">
      {combinedData.foodItems?.map((product) => (
        <div key={product.foodItemId} className="product-card">
          {product?.image && (
            <img
              onClick={() => navigate(`/cartitem/${product.foodItemId}`)}
              src={
                product?.image?.includes("https://")
                  ? product.image
                  : `https://horeloimgs.s3.amazonaws.com/food-item/${product.image}`
              }
              alt="logo"
            />
          )}
          <h3>
            {product.name}
            {product.foodItemId}
          </h3>
          <p>{product.description}</p>
          <p>Price: ${product.price.basePrice}</p>
          <button
            onClick={() => handleAddToFoodItemCart(product)}
            disabled={isItemInFoodItemCart(product.foodItemId)}
          >
            <FaPlusCircle className="add-icon" />
            Add to Cart
          </button>
        </div>
      ))}
      {combinedData?.products.map((product) => (
        <div key={product.productId} className="product-card">
          {product?.image && (
            <img
              onClick={() => navigate(`/cartitem/${product.productId}`)}
              src={
                product?.image?.includes("https://")
                  ? product.image
                  : `https://horeloimgs.s3.amazonaws.com/product/${product.image}`
              }
              alt="logo"
            />
          )}
          <h3>
            {product.name}
            {product.productId}
          </h3>
          <p>{product.description}</p>
          <p>Price: ${product.price.basePrice}</p>
          <button
            onClick={() => handleAddToProductCart(product)}
            disabled={isItemInProductCart(product.productId)}
          >
            <FaPlusCircle className="add-icon" />
            Add to Cart
          </button>
        </div>
      ))}
    </div>
  );
};

export default Home;
