/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useCollapse } from "react-collapsed";
import Tabs from "../components/Tabs";
import ProfileScreen from "./user/ProfileScreen";
import CompanyList from "./company/CompanyList";
import CompanyAdd from "./company/CompanyAdd";
import AddAllergy from "./allergy/AddAllergy";
import AllergyList from "./allergy/AllergyList";

const Profile = () => {
  const [isStoreExpanded, setStoreExpanded] = useState(false);
  const [isAllergyExpanded, setAllergyExpanded] = useState(false);

  const {
    getCollapseProps: getStoreCollapseProps,
    getToggleProps: getStoreToggleProps,
  } = useCollapse({ isExpanded: isStoreExpanded });

  const {
    getCollapseProps: getAllergyCollapseProps,
    getToggleProps: getAllergyToggleProps,
  } = useCollapse({ isExpanded: isAllergyExpanded });

  return (
    <Tabs defaultActiveTab={0}>
      <div title="User">
        <ProfileScreen />
      </div>
      <div title="Store">
        <div>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            {...getStoreToggleProps({
              onClick: () => setStoreExpanded((prev) => !prev),
            })}
          >
            {isStoreExpanded ? "Cancel" : "Add Store"}
          </button>
          <section {...getStoreCollapseProps()}>
            <CompanyAdd />
          </section>
        </div>
        <section
          style={{ width: "400px", height: "200px", overflow: "scroll" }}
        >
          <CompanyList />
        </section>
      </div>
      <div title="Allergies">
        <div>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            {...getAllergyToggleProps({
              onClick: () => setAllergyExpanded((prev) => !prev),
            })}
          >
            {isAllergyExpanded ? "Cancel" : "Add Allergy"}
          </button>
          <section {...getAllergyCollapseProps()}>
            <AddAllergy />
          </section>
          <section
            style={{ width: "400px", height: "200px", overflow: "scroll" }}
          >
            <AllergyList />
          </section>
        </div>
      </div>
    </Tabs>
  );
};

export default Profile;
