/* eslint-disable no-unused-vars */
import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
// eslint-disable-next-line
import {
  useGetAllFoodItemsQuery,
  useGetAllFoodItemsAndProductsQuery,
} from "../../../auth/services/foodItemService";

const ProductList = () => {
  // Use the query hook to fetch data from the API
  //const { data: foodItems, error, isLoading } = useGetAllFoodItemsQuery();
  const {
    data: combinedData,
    error,
    isLoading,
  } = useGetAllFoodItemsAndProductsQuery();

  // Check for loading state
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Check for error state
  if (error) {
    return <div>Error loading food items.</div>;
  }

  // Dynamic text color logic based on status
  const textColor = (status) => {
    if (status.toLowerCase() === "public") {
      return "green";
    } else if (status === "private") {
      return "orange";
    } else {
      return "blue";
    }
  };

  return (
    <div className="shadow mx-1 px-3 bg-light rounded-3 ">
      <div className="d-flex align-items-center justify-content-between p-3 mt-3">
        <h4 className="mb-0">Products List</h4>
        <div className="ml-auto">
          <button className="btn mr-2 btn-primary">See All</button>
          <Link to="product" className="btn btn-primary">
            Add
          </Link>
        </div>
      </div>
      <div className="">
        <table className="table">
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Price</th>
              <th>Visibility</th>
            </tr>
          </thead>
          <tbody>
            {combinedData.foodItems.map((product, index) => (
              <tr key={index}>
                <td>
                  {product?.image && (
                    <img
                      src={
                        product?.image?.includes("https://")
                          ? product.image
                          : `https://horeloimgs.s3.amazonaws.com/food-item/${product.image}`
                      }
                      alt="logo"
                    />
                  )}
                </td>
                <td>{product.name}</td>
                <td>{product.price.basePrice}</td>
                <td style={{ color: textColor(product.visibility) }}>
                  {product.visibility}
                </td>
                <td className="flex gap-2">
                  <Link
                    to={`product/fooditem/${product.foodItemId}`}
                    className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded"
                  >
                    View
                  </Link>
                  <Link
                    to={`product/editfooditem/${product.foodItemId}`}
                    className="bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded"
                  >
                    Edit
                  </Link>
                </td>
              </tr>
            ))}
            {combinedData.products.map((product, index) => (
              <tr key={index}>
                <td>
                  {product?.image && (
                    <img
                      src={
                        product?.image?.includes("https://")
                          ? product.image
                          : `https://horeloimgs.s3.amazonaws.com/product/${product.image}`
                      }
                      alt="logo"
                    />
                  )}
                </td>
                <td>{product.name}</td>
                <td>{product.price.basePrice}</td>
                <td>{product?.visibility}</td>
                <td className="flex gap-2">
                  <Link
                    to={`product/${product.productId}`}
                    className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded"
                  >
                    View
                  </Link>
                  <Link
                    to={`product/editproduct/${product.productId}`}
                    className="bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded"
                  >
                    Edit
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductList;
