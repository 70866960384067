import React, { useState } from "react";
import { useCreateAllergyDetailsMutation } from "../../auth/services/allergyService";

const AddAllergy = () => {
  const [createAllergy, { isLoading, error }] =
    useCreateAllergyDetailsMutation();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createAllergy({ data: formData }).unwrap();
      alert("Allergy created successfully!");
      setFormData({ name: "", description: "" });
    } catch (err) {
      console.error("Error creating allergy:", err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Create New Allergy</h2>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="Allergy Name"
        required
      />
      <input
        type="text"
        name="description"
        value={formData.description}
        onChange={handleChange}
        placeholder="Description"
        required
      />
      <button type="submit" disabled={isLoading}>
        {isLoading ? "Creating..." : "Create Allergy"}
      </button>
      {error && <p>Error: {error.message}</p>}
    </form>
  );
};

export default AddAllergy;
