import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  useUpdateIngredientDetailsMutation,
  useGetIngredientInfoQuery,
} from "../../../auth/services/ingredientService"; // Adjust the path if needed
import "./index.css";

const IngredientDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the ingredient ID from the URL
  const userId = useSelector((state) => state.auth.userInfo.id);
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // use the mutation hook for updating ingredient
  const [updateIngredientDetails] = useUpdateIngredientDetailsMutation();

  // Fetch existing ingredient details to populate the form
  const { data: ingredient, isLoading, error } = useGetIngredientInfoQuery(id);

  // State to capture form data
  const [ingredientData, setIngredientData] = useState({
    name: "",
    description: "",
    category: "",
    price: {
      basePrice: 0,
      discount: 1.1,
      currency: "USD",
    },
    estoreId,
    userId, // Use userId from store
  });

  // Populate the form with fetched ingredient data
  useEffect(() => {
    if (ingredient) {
      setIngredientData({
        name: ingredient.name,
        description: ingredient.description,
        category: ingredient.category,
        price: {
          basePrice: ingredient.price.basePrice,
          discount: ingredient.price.discount,
          currency: ingredient.price.currency || "USD",
        },
        estoreId,
        userId,
      });
    }
  }, [ingredient, estoreId, userId]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "basePrice") {
      setIngredientData((prevData) => ({
        ...prevData,
        price: { ...prevData.price, basePrice: parseFloat(value) }, // Ensure it's a number
      }));
    } else {
      setIngredientData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleBackClick = () => {
    navigate("/dashboard");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Make API call to update the ingredient
      await updateIngredientDetails(
        id,
        JSON.stringify(ingredientData)
      ).unwrap();
      console.log("Ingredient updated successfully!");
      navigate("/dashboard"); // Redirect after updating
    } catch (error) {
      console.error("Error updating ingredient:", error);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading ingredient data</div>;

  return (
    <div className="edit-ingredient-container">
      <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
        Back to Dashboard
      </button>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Ingredient Name{id}</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={ingredientData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Ingredient Description</label>
          <textarea
            className="form-control"
            rows="3"
            name="description"
            value={ingredientData.description}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label>Ingredient Category</label>
          <select
            className="form-control"
            name="category"
            value={ingredientData.category}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Category</option> {/* Placeholder option */}
            <option value="spices">Spices</option>
            <option value="vegetables">Vegetables</option>
            <option value="proteins">Proteins</option>
            <option value="liquids">Soup/Broth</option>
            <option value="grains">Grains</option>
            <option value="dairy">Dairy</option>
            <option value="condiments">Condiments</option>
            <option value="others">other</option>
          </select>
        </div>
        <div className="form-group">
          <label>Ingredient Price</label>
          <input
            type="number"
            className="form-control"
            name="basePrice"
            value={ingredientData.price.basePrice}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Update Ingredient
        </button>
      </form>
    </div>
  );
};

export default IngredientDetails;
