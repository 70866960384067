import "./index.css";
import VerticalNavbar from "./VerticalNavBar";
import Body from "./Body";

function Dashboard() {
  return (
    <div className="d-flex ">
      {" "}
      <VerticalNavbar /> <Body />{" "}
    </div>
  );
}
export default Dashboard;
