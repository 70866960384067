import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./FoodItemDetails.css"; // Create a separate CSS file for styling if needed
import { useGetFoodItemInfoQuery } from "../../../auth/services/foodItemService";

const FoodItemDetails = () => {
  const { id } = useParams(); // Get the food item ID from the URL
  const navigate = useNavigate();

  // Fetch the food item details using the query hook
  const { data: foodItem, error, isLoading } = useGetFoodItemInfoQuery(id);

  const handleBackClick = () => {
    navigate("/dashboard");
  };
  const handleEditClick = (id) => {
    navigate(`/dashboard/editfooditem/${id}`);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching food item details</div>;
  }
  const renderCombinedIngredients = () => {
    alert(JSON.stringify(foodItem));
    alert(JSON.stringify(foodItem.combinedIngredients));
    if (Array.isArray(foodItem.combinedIngredients)) {
      // Handle case where combinedIngredients is an array
      return <p>{foodItem.combinedIngredients.join(", ")}</p>;
    } else if (typeof foodItem.combinedIngredients === "string") {
      // Handle case where combinedIngredients is a string
      return <p>{foodItem.combinedIngredients}</p>;
    } else if (
      typeof foodItem.combinedIngredients === "object" &&
      foodItem.combinedIngredients !== null
    ) {
      // Handle case where combinedIngredients is an object
      return (
        <div>
          {foodItem.combinedIngredients.garnish?.length > 0 && (
            <div>
              <strong>Garnishes:</strong>{" "}
              {foodItem.combinedIngredients.garnish.join(", ")}
            </div>
          )}
          {foodItem.combinedIngredients.spiceLevels?.length > 0 && (
            <div>
              <strong>Spice Levels:</strong>{" "}
              {foodItem.combinedIngredients.spiceLevels.join(", ")}
            </div>
          )}
          {foodItem.combinedIngredients.liquidAddons?.length > 0 && (
            <div>
              <strong>Liquid Add-ons:</strong>{" "}
              {foodItem.combinedIngredients.liquidAddons.join(", ")}
            </div>
          )}
          {foodItem.combinedIngredients.solidAddons?.length > 0 && (
            <div>
              <strong>Solid Add-ons:</strong>{" "}
              {foodItem.combinedIngredients.solidAddons.join(", ")}
            </div>
          )}
          {foodItem.combinedIngredients.foodCourses?.length > 0 && (
            <div>
              <strong>Course Kind:</strong>{" "}
              {foodItem.combinedIngredients.foodCourses.join(", ")}
            </div>
          )}
          {foodItem.combinedIngredients.otherIngredients?.length > 0 && (
            <div>
              <strong>Main Ingredients:</strong>{" "}
              {foodItem.combinedIngredients.otherIngredients.join(",")}
            </div>
          )}
        </div>
      );
    }
    // Handle case where ingredients are not available
    return <p>No ingredients available</p>;
  };
  return (
    <div className="food-item-details-container">
      <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
        Back to Dashboard
      </button>

      {foodItem && (
        <div className="food-item-details">
          <h3>
            {foodItem.name}
            {}
          </h3>
          <img
            src={foodItem.image}
            alt={foodItem.name}
            className="food-item-image"
          />
          <div className="food-item-info">
            <p>
              <strong>Cuisine Type:</strong> {foodItem.cuisine_type}
            </p>
            <p>
              <strong>Spice Level:</strong> {foodItem.spice_level}
            </p>
            <p>
              <strong>Price:</strong> ${foodItem.price.basePrice.toFixed(2)}{" "}
              {foodItem.price.currency}
              {foodItem.price.discount && (
                <span> (Discount: ${foodItem.price.discount.toFixed(2)})</span>
              )}
            </p>
            <p>
              <strong>Description:</strong> {foodItem.description}
            </p>
            <p>
              <strong>Ingredients:</strong> {foodItem.ingredients}
            </p>
            <div className="ingredients-section">
              <h4>Selectable Items:</h4>
              {renderCombinedIngredients()}
            </div>
            <p>
              <strong>Available Days:</strong> {foodItem.days_available}
            </p>
            <p>
              <strong>Image url:</strong> {foodItem.image}
            </p>
            <p>
              <strong>Visibility:</strong>{" "}
              {foodItem.visibility === "PUBLIC" ? "Public" : "Private"}
            </p>
          </div>
          <button
            onClick={() => handleEditClick(foodItem.foodItemId)}
            className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded"
          >
            Edit
          </button>
          <button
            onClick={() => handleEditClick(foodItem.foodItemId)}
            className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded"
          >
            Change Image
          </button>
        </div>
      )}
    </div>
  );
};

export default FoodItemDetails;
