import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCreateProductDetailsMutation } from "../../../auth/services/productService"; // Adjust the path if needed
import "./index.css";

const AddProduct = () => {
  const navigate = useNavigate();
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // use the mutation hook for creating product
  const [createProductDetails] = useCreateProductDetailsMutation();

  // State to capture form data
  const [productData, setProductData] = useState({
    name: "",
    description: "",
    category: "",
    price: {
      basePrice: 0,
      discount: 1.1,
      currency: "USD",
    },
    estoreId, // assuming estoreId and userId are known or from props/store
    //userId: 101,
  });

  // handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "basePrice") {
      setProductData((prevData) => ({
        ...prevData,
        price: { ...prevData.price, basePrice: parseFloat(value) }, // Ensure it's a number
      }));
    } else {
      setProductData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleBackClick = () => {
    navigate("/dashboard");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // make API call to create product
      alert(JSON.stringify(productData));
      await createProductDetails(productData).unwrap();
      console.log("Product added successfully!");
      navigate("/dashboard"); // Redirect after adding
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  return (
    <div className="add-product-container">
      <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
        Back to Dashboard
      </button>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Product Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={productData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Product Description</label>
          <textarea
            className="form-control"
            rows="3"
            name="description"
            value={productData.description}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label>Product Category</label>
          <input
            type="text"
            className="form-control"
            name="category"
            value={productData.category}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Product Price</label>
          <input
            type="number"
            className="form-control"
            name="basePrice"
            value={productData.price.basePrice}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Add Product
        </button>
      </form>
    </div>
  );
};

export default AddProduct;
