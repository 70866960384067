import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Error from "../components/Error";
import Spinner from "../components/Spinner";
import { useForm } from "react-hook-form";
import { userLogin } from "../redux/auth/authActions";
import { useEffect } from "react";
/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Login() {
  const { loading, userToken, error } = useSelector((state) => state.auth);
  const { register, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = function (data) {
    dispatch(userLogin(data));
  };
  useEffect(() => {
    reset();

    if (userToken) {
      navigate("/profiles");
    }
  }, [userToken, navigate, reset]);
  return (
    <div className="w-full max-w-xs">
      <form
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        {error && <Error>{error}</Error>}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="email"
            id="email"
            type="email"
            placeholder="Email"
            {...register("email")}
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Password
          </label>
          <input
            className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            name="password"
            id="password"
            type="password"
            placeholder="******************"
            {...register("password")}
          />
          <p className="text-red-500 text-xs italic">
            Please enter a password.
          </p>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
            disabled={loading}
          >
            {loading ? <Spinner /> : "Login"}
          </button>
          <a
            href="#"
            className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
          >
            Forgot Password?
          </a>
        </div>
      </form>
      <Link
        to="/signup"
        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-12 rounded focus:outline-none focus:shadow-outline"
      >
        Sign up
      </Link>
    </div>
  );
}
