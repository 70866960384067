import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./index.css"; // Import the CSS file
import { useCreateIngredientWithNameMutation } from "../../../auth/services/ingredientService"; // Adjust the path if needed
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the toast styles
const GarnishToppings = ({ garnishes, setGarnishes }) => {
  const [newGarnish, setNewGarnish] = useState("");
  const [createIngredientWithName] = useCreateIngredientWithNameMutation();

  // Retrieve userId and estoreId from Redux store
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // Submit ingredient to server
  const submitIngredientData = async (name, category) => {
    const data = {
      name,
      description: "",
      category,
      price: {
        basePrice: 0,
        discount: 1.1,
        currency: "USD",
      },
      estoreId,
    };

    try {
      const result = await createIngredientWithName({ data }).unwrap();
      toast.success("Garnish Item submitted successfully:", result);
    } catch (error) {
      toast.error("Error submitting data:", error);
    }
  };
  // Handle adding a new garnish or topping
  const addGarnish = async () => {
    if (newGarnish.trim()) {
      await submitIngredientData(newGarnish.trim(), "Garnishes");
      setGarnishes([...garnishes, { name: newGarnish.trim(), checked: true }]);
      setNewGarnish(""); // Clear the input field
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedGarnishes = garnishes.map((garnish, i) =>
      i === index ? { ...garnish, checked: !garnish.checked } : garnish
    );
    setGarnishes(updatedGarnishes);
  };

  return (
    <div className="garnish-toppings-container">
      <h3 className="garnish-toppings-title">Garnish / Toppings</h3>
      <ul className="garnish-toppings-list">
        {garnishes.map((garnish, index) => (
          <li className="garnish-toppings-list-item" key={index}>
            <input
              id={`garnish-checkbox-${index}`} // Unique id for each checkbox
              name={`garnish-checkbox-${index}`}
              type="checkbox"
              checked={garnish.checked}
              onChange={() => handleCheckboxChange(index)}
            />
            <label htmlFor={`garnish-checkbox-${index}`}>{garnish.name}</label>
          </li>
        ))}
      </ul>
      <div>
        <input
          id={`garnish-checkbox`}
          name={`garnish-checkbox`}
          type="text"
          className="garnish-toppings-input"
          value={newGarnish}
          onChange={(e) => setNewGarnish(e.target.value)}
          placeholder="Add new garnish or topping"
        />
        <button className="garnish-toppings-button" onClick={addGarnish}>
          Add
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default GarnishToppings;
