import React, { useEffect, useState } from "react";
import {
  useUpdateEstoreOpenDaysAndHoursMutation,
  useGetEstoreInfoQuery,
} from "../../auth/services/estoreService";
import { useParams } from "react-router-dom";
import "./OpenDaysAndHoursEditor.css";

const DAYS_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const OpenDaysAndHoursEditor = () => {
  const { id } = useParams();
  const [updateEstoreOpenDaysAndHours] =
    useUpdateEstoreOpenDaysAndHoursMutation();
  const { data: estore, isLoading, error } = useGetEstoreInfoQuery(id);
  const [openDaysAndHours, setOpenDaysAndHours] = useState([]);

  useEffect(() => {
    if (estore) {
      // Create an initial state with all days of the week
      const initialOpenDaysAndHours = DAYS_OF_WEEK?.map((day) => {
        const existingDayData =
          estore?.openDaysAndHours?.openPeriodsByDay?.find(
            (periodForDay) => periodForDay.dayOfWeek === day
          );
        return (
          existingDayData || {
            dayOfWeek: day,
            openPeriods: [],
          }
        );
      });
      setOpenDaysAndHours(initialOpenDaysAndHours);
    }
  }, [estore]);

  const handleChange = (dayIndex, periodIndex, field, value) => {
    // Create a deep copy of the openDaysAndHours array
    const updatedDaysAndHours = openDaysAndHours.map((day, i) => {
      if (i === dayIndex) {
        // Create a deep copy of openPeriods
        const updatedPeriods = day.openPeriods.map((period, j) =>
          j === periodIndex ? { ...period, [field]: value } : period
        );
        return { ...day, openPeriods: updatedPeriods };
      }
      return day;
    });
    setOpenDaysAndHours(updatedDaysAndHours);
  };

  const handleAddPeriod = (dayIndex) => {
    // Create a deep copy of the openDaysAndHours array
    const updatedDaysAndHours = openDaysAndHours.map((day, i) => {
      // For the specific day, create a deep copy of openPeriods
      if (i === dayIndex) {
        return {
          ...day,
          openPeriods: [...day.openPeriods, { openTime: "", closeTime: "" }],
        };
      }
      return day;
    });
    setOpenDaysAndHours(updatedDaysAndHours);
  };

  const handleSubmit = async () => {
    // Create the payload object
    const payload = {
      openPeriodsByDay: openDaysAndHours, // Wrap the openDaysAndHours inside the key
    };

    alert(JSON.stringify(payload)); // For debugging

    try {
      const result = await updateEstoreOpenDaysAndHours({
        id,
        data: payload, // Send the payload with the correct structure
      }).unwrap();
      alert(JSON.stringify(result)); // For debugging
      console.log("Data updated successfully:", result);
    } catch (error) {
      alert(error);
      console.error("Error updating data:", error);
    }
  };

  if (isLoading) {
    return <div>Loading...Open Days And Hours </div>;
  }

  if (error) {
    return <div>Error fetching Open Days and Hours details</div>;
  }

  return (
    <div>
      <h2>Edit Open Days and Hours</h2>
      <div className="days-container">
        {openDaysAndHours?.map((periodForDay, dayIndex) => (
          <div key={dayIndex} className="day-card">
            <h3>{periodForDay.dayOfWeek}</h3>
            {periodForDay?.openPeriods?.map((period, periodIndex) => (
              <div key={periodIndex} className="period-inputs">
                <label>
                  Open Time:
                  <input
                    type="time"
                    value={period.openTime}
                    onChange={(e) =>
                      handleChange(
                        dayIndex,
                        periodIndex,
                        "openTime",
                        e.target.value
                      )
                    }
                  />
                </label>
                <label>
                  Close Time:
                  <input
                    type="time"
                    value={period.closeTime}
                    onChange={(e) =>
                      handleChange(
                        dayIndex,
                        periodIndex,
                        "closeTime",
                        e.target.value
                      )
                    }
                  />
                </label>
              </div>
            ))}
            <button onClick={() => handleAddPeriod(dayIndex)}>
              Add Period
            </button>
          </div>
        ))}
      </div>
      <button onClick={handleSubmit}>Update Open Hours</button>
    </div>
  );
};

export default OpenDaysAndHoursEditor;
