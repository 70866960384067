import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserDetails } from "../../redux/auth/userSlice";

function UserProfile() {
  const dispatch = useDispatch();
  const { userInfo, loading, error } = useSelector((state) => state.auth);

  // Fetch user details when component loads
  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  return (
    <div className="w-full p-4 text-center bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
      <div className="flex justify-center w-screen">
        <div className="p-4">
          <a href="#">
            <img
              className="mb-2 rounded w-36 h-36"
              src="https://www.zdnet.com/a/img/resize/7c135e7748ad80aa72743c58c1d067ba1a0fddcf/2023/10/06/4e7663f4-fe43-424e-8fde-64a5612cdfd7/img-1950.jpg?auto=webp&width=1280"
              alt="text"
            />
          </a>
        </div>
      </div>
      <h5 className="mb-2 text-3xl font-bold text-gray-900 dark:text-white">
        {userInfo?.userDetails?.preferredName ||
          userInfo?.userDetails?.firstName ||
          "I need a name"}
      </h5>
      <p className="mb-5 text-base text-gray-500 sm:text-lg dark:text-gray-400">
        {userInfo?.userDetails?.title || "I need a title"}
      </p>
      <p className="mb-5 text-base text-gray-500 sm:text-lg dark:text-gray-400">
        {userInfo?.role || "I need a role"}
      </p>

      <div className="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 rtl:space-x-reverse">
        <a
          href="#"
          className="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
        >
          <svg className="me-3 w-7 h-7" aria-hidden="true">
            {/* SVG Path */}
          </svg>
          <div className="text-left rtl:text-right">
            <div className="mb-1 text-xs">Download on the</div>
            <div className="-mt-1 font-sans text-sm font-semibold">
              Mac App Store
            </div>
          </div>
        </a>
      </div>

      <div>
        <h1>User Profile</h1>
        {loading ? (
          <>
            <p>Loading...</p>
            <p>Email: {userInfo?.email}</p>
          </>
        ) : error ? (
          <p>Error: {error}</p>
        ) : userInfo ? (
          <div>
            <p>Email: {userInfo?.email}</p>
            <p>Preferred Name: {userInfo?.preferredName}</p>
            <p>First Name: {userInfo?.userProfile.firstName}</p>
            <p>Last Name: {userInfo?.userProfile.lastName}</p>
            <p>Phone Number: {userInfo?.userProfile.phoneNumber}</p>
            <p>Title: {userInfo?.userProfile.title}</p>
            <p>Description: {userInfo?.userProfile.description}</p>
            <p>
              Avatar: <img src={userInfo?.userProfile.avatar} alt="Avatar" />
            </p>
          </div>
        ) : (
          <p>No user details found.</p>
        )}
      </div>
    </div>
  );
}

export default UserProfile;
