import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { useSelector } from "react-redux";
const VerticalNavbar = () => {
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  return (
    <nav className="navbar navbar-expand-lg navbar-light ">
      <ul className="navbar-nav d-flex flex-column">
        <li className="nav-item">
          <a className="nav-link" href="dashboard">
            Dashboard
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="dashboard">
            Orders
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="dashboard">
            Products
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="dashboard">
            Reports
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href={`dashboard/estore-edit/${estoreId}`}>
            Settings
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default VerticalNavbar;
